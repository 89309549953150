import { useEffect, useState } from "react";
import ThumbPath from "../../concern/ThumbPath";
import Loading from "../../concern/Loading";
import Body from "../../concern/Body";
import NotFound from "../../concern/NotFound";

export default function Main(args: { [key: string]: string }) {
  let [resp, setResp] = useState<{ [key: string]: any }>();

  useEffect(() => {
    (async () => {
      const resp = await (document as any).fetch(`follows/${args.token}`);
      setResp(resp);
    })();
  }, [args.token]);

  if (resp === undefined) return <Loading />;
  if (!resp.user.id) return <NotFound />;

  return (
    <main className="container pt-4">
      <div className="row">
        <div className="col-lg-4">
          <div className="card mb-4">
            <div className="card-body text-center">
              <img
                src={ThumbPath(resp.user)}
                alt="avatar"
                className="rounded-circle img-fluid"
                style={{ width: "150px" }}
              />
              <h5 className="my-3">{resp.user.name}</h5>
              <p className="text-muted mb-1">
                {resp.followCount.followers} フォロワー
              </p>
              <p className="text-muted mb-4">
                {resp.followCount.following} フォロー中
              </p>
              <div className="d-flex justify-content-center mb-2">
                <button type="button" className="btn btn-outline-primary me-1">
                  <i className="fa-solid fa-ellipsis" />
                </button>
                <button type="button" className="btn btn-primary">
                  フォロー
                </button>
              </div>
            </div>
          </div>
          {resp.weight.length !== 0 && (
            <div className="card mb-4 mb-lg-0">
              <div className="card-body p-0">
                <ul className="list-group list-group-flush rounded-3">
                  {resp.weight.map((row: any) => (
                    <li
                      key={row.id}
                      className="list-group-item d-flex justify-content-between align-items-center p-3"
                    >
                      <p className="mb-0">{row.name}</p>
                      <p className="mb-0">
                        {(row.total || 0).toLocaleString()} (MAX:
                        {(row.weight || 0).toLocaleString()})
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
        <div className="col-lg-8">
          <div className="card mb-4">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-4">
                  <p className="mb-0">最終トレーニング日</p>
                </div>
                <div className="col-sm-8">
                  <p className="text-muted mb-0">
                    {resp.finalTrainingDate
                      ? resp.finalTrainingDate.replace(/-/g, "/")
                      : "-"}
                  </p>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-4">
                  <p className="mb-0">直近1ヶ月のトレーニング頻度</p>
                </div>
                <div className="col-sm-8">
                  <p className="text-muted mb-0">週{resp.frequency}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-4">
            <div className="card-body">
              <h6 className="card-title">超回復の状況</h6>
              <Body trainings={resp.recovery} />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
